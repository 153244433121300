import * as React from "react"
import Layout from '../templates/layout';
import PbbaLogo from '../assets/images/pbba-logo.png';

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
  listStyleType: "none",
}
const listItemStyles = {
  marginBottom: 12,
  fontWeight: "300",
  letterSpacing: 1,
}
const linkStyles = {
  color: "#8954A8",
}

// data
const links = [
  {
    text: "Documentation",
    url: "https://www.gatsbyjs.com/docs/",
  },
  {
    text: "Tutorials",
    url: "https://www.gatsbyjs.com/tutorial/",
  },
  {
    text: "Guides",
    url: "https://www.gatsbyjs.com/tutorial/",
  },
  {
    text: "API Reference",
    url: "https://www.gatsbyjs.com/docs/api-reference/",
  },
  {
    text: "Plugin Library",
    url: "https://www.gatsbyjs.com/plugins",
  },
  {
    text: "Cheat Sheet",
    url: "https://www.gatsbyjs.com/docs/cheat-sheet/",
  },
]

// markup
const IndexPage = () => {
  return (
    <Layout title="Home">
        <div id="front-header" className="front-section cover-bg">
            <div className="container">
                <div className="section-text">
                    <h1>Kahler Homes of Midland</h1>
                    <div className="intro-lead-in">Since 1981</div>
                </div>
            </div>
        </div>
        <div className="front-section front-boast">
            <div className="container">
                <div className="row flex align-items-center">
                    <div className="col-md-5 text-right">
                            <img src={PbbaLogo} height="75" />
                    </div>
                    <div className="col-md-7">
                            <p className="lead">Winner of the 2020 Parade of Homes!</p>
                            <ul className="list-unstyled">
                                <li>Best in Show</li>
                                <li>Favorite Kitchen</li>
                                <li>Best Interior Design</li>
                                <li>Best Curb Appeal</li>
                                <li>Favorite Outdoor Living</li>
                            </ul>
                                <p>
                                    <a href="/parade/" title="Parade of Homes">View more  of our beautiful home!</a>
                                </p>
                    </div>
                </div>
                {/* <div className="row text-center">
                      <h3>Open House!</h3>
                      <p>5829 Costa Mesa</p>
                      <p>October 26 and 27</p>
                      <p>1:00PM-4:00PM</p>
                </div> */}
                    
            </div>
            <div className="text-center announcement">
                {/* <p className="lead"><a href="https://www.surveymonkey.com/r/PBBAPARADE" target="_blank">Vote for People's Choice--Permian Basin Parade of Homes 2019</a></p> */}
                
            </div>
        </div>
        <div className="front-section front-boast">
                <div className="row flex">
                    <img src="./assets/img/pbba-parade-of-homes-flyer-2020.jpg" width="700px" style={{margin: '0 auto'}} alt="" />
                </div>
                {/* <div className="row text-center">
                      <h3>Open House!</h3>
                      <p>5829 Costa Mesa</p>
                      <p>October 26 and 27</p>
                      <p>1:00PM-4:00PM</p>
                </div> */}
                    
        </div>
        <div id="front-provide" className="front-section">
            <div className="container">
                <div className="section-text">
                    <h1>What We Provide</h1>
                    <div className="row content">
                        <p>Our clients claim many benefits in addition to our
                            <a href="/features/" title="Standard Features">standard features</a>.
                        </p>
                    </div>
                    <div className="row content">
                        <div className="col-sm-4">
                            <h2>Design</h2>
                            <p>
                                You can choose a spec home if you prefer to purchase a finished product, or
                                choose a custom home ranging from 1,400 to 6,000 square feet. Custom home architectural
                                selections and
                                amenities are limited only by your own imagination.
                            </p>
                        </div>
                        <div className="col-sm-4">
                            <h2>Protection</h2>
                            <p>Our homes are protected with a ten year <a href="https://www.strucsure.com%20">StrucSure
                                    warranty</a></p>
                        </div>
                        <div className="col-sm-4">
                            <h2>Location</h2>
                            <p>
                                We can build anywhere in the Midland area. Choose to build on your lot or one of ours!
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div id="front-developments" className="front-section cover-bg">
            <div className="container">
                <div className="section-text">
                    <h1 className="white">Developments</h1>
                    <div className="row content">
                        <div className="col-sm-4 section-content">
                            <p>Kahler Homes is currently involved in the <a href="https://www.midlandheritageoaks.com/" target="_blank">Heritage Oaks</a>, 
                            <a href="https://thevineyardmidland.com/" target="_blank">The Vineyard</a>, Pavilion Park, Country Sky, and Saddleback Estates residential developments in
                                Midland.
                                Lots are available in each of these developments. Lot pricing and availablilty are
                                available.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    
  )
}

export default IndexPage
